<template>
  <div class="form-C">
    <div class="form-bg">
       <div class="form-1">
         <div class="form-item">
           <p>{{ $t("contactForm.name") }}<span>*</span></p>
           <input type="text" class="form-item-input" v-model="name">
         </div>
       </div>
      <div class="form-1">
        <div class="form-item">
          <p>{{ $t("contactForm.telephone") }}<span>*</span></p>
          <input type="text" class="form-item-input"  v-model="phone">
        </div>

      </div>

      <div class="form-1">
        <div class="form-item">
          <p>{{ $t("contactForm.email") }}<span>*</span></p>
          <input type="text" class="form-item-input" v-model="email">
        </div>
      </div>

    <div class="form-1 form-2">
      <p>{{ $t("contactForm.message") }}<span>*</span></p>
      <textarea class="content" v-model="message"></textarea>
    </div>

    <div class="send">
      <div class="common-btn"  :class="{ 'disabled': isSubmitting }" @click="submitForm">
        <div>
          {{ $t("contactForm.submit") }} <span><i class="iconfont icon-youjiantou"></i></span>
        </div>
      </div>


    </div>
  </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

  <div class="loading-container" v-if="sLoading">
    <div class="loading-indicator"></div>
    <div class="loading-text">{{ $t("loading") }}...</div>
  </div>

</template>

<script>
import {contactForm} from "@/network/main";

export default {
  name: "form",
  data() {
    return {
      name:'',
      phone:'',
      email:'',
      message:'',
      isPopupVisible: false,
      popupText: '',
      isSubmitting: false,
      sLoading: false,
    };
  },
  methods:{
    //不可两次提交
    submitForm() {
      if (!this.isSubmitting) {
        this.isSubmitting = true;
        this.sendForm();
      }
    },
    //提交数据
    sendForm(){


      //驗證必填
      if (!this.name || !this.phone || !this.email || !this.message) {

        this.isPopupVisible = true
        this.popupText=this.$t('contactForm.required')
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('phone', this.phone);
      if (this.email) {
        if (!this.validateForm()) {
          return;
        }
        formData.append('email', this.email);
      }
      if (this.message) {
        formData.append('message', this.message);
      }

      //
      this.sLoading = true;

      //提交数据
      contactForm(formData).then((res)=>{
        if(res.data.code===200){
          this.sLoading = false
          this.isPopupVisible = true
          this.popupText=this.$t('contactForm.success')
          setTimeout(() => {
            this.isPopupVisible = false;
            this.name = "";
            this.phone = "";
            this.email = "";
            this.message = "";
            this.$router.push('/');
          }, 2000);
        }else{
          this.isPopupVisible = true;
          this.popupText=res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err=> {
        console.log(err)
      })
    },
    // 验证电子邮件字段
    validateForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email || !emailRegex.test(this.email)) {
            this.isPopupVisible = true,
            this.popupText=this.$t('contactForm.tips')
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return false;
      }
      return true; // 表单验证通过
    },
  }
}
</script>

<style scoped>
.form-C{
  width: 100%;
  margin: 0 auto;
}

.form-1{
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.form-item{
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.form-item p{
  width: 100px;
  padding-bottom: 5px;
}
.form-item p span, .form-2 p span{
  color: #ff0000;
  padding-left: 5px;
}
.form-item-input{
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: #fff;
  font-size: 20px;
  border: 1px solid #C8C8C8;
  padding: 0 10px;
}

.form-2 p{
   width: 100px;
}

.choose div{
  width: 25%;
  margin-top: 10px;
}
.content{
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  height: 80px;
  resize: none;
  border: 1px solid #C8C8C8;
}
.send{
  display: flex;
  margin-top: 30px;
}
.send button{
  padding: 15px 50px;
  line-height: inherit;
  height: inherit;
  background: var(--background-high);
  border: none;
  color: #fff;
  font-size:18px;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
  white-space: pre-line;
  line-height: 2;
}
.common-btn.disabled>div{
  background-color: #ccc;
  cursor: not-allowed;
}

@media only screen and (min-width:0) and (max-width:767px){
  .form-C{
    width: 100%;
  }
  .form-bg{
    padding: 0;
  }
  .form-bg h1{
    font-size: 24px;
  }
  .form-1{
    flex-wrap: wrap;
    padding-top: 0;

  }
  .form-item{
    width: 100%;
    margin-top: 20px;
  }
  .form-2{
    margin-top: 20px;
  }
  .choose div{
    width:50%;
  }
  .content{
    height: 120px;
  }

}
</style>