<template>

  <div v-if="isShow">
    <Loading/>
  </div>

  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("contact") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/contact">
            {{ $t("contact") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>


  <div class="minHeight middle common-padding-100">

    <div class="contact">
      <div class="contact-left">
        <h1>
          OSONG<br/>
          ELECTRIC CO., LTD.
        </h1>

        <div class="contact-info">
          <div class="contact-info-li">
            <div class="info-icon">
              <i class="iconfont icon-dizhi"></i>
            </div>
            <div class="info-txt">
              <h4>{{ $t("address") }}</h4>
              <p><b>{{ address }}</b></p>
            </div>
          </div>

          <div class="contact-info-li">
            <div class="info-icon">
              <i class="iconfont icon-dianhua"></i>
            </div>
            <div class="info-txt">
              <h4>{{ $t("tel") }}</h4>
              <p><b>{{ phone }}</b></p>
            </div>
          </div>

          <div class="contact-info-li">
            <div class="info-icon">
              <i class="iconfont icon-fax-fill"></i>
            </div>
            <div class="info-txt">
              <h4>{{ $t("fax") }}</h4>
              <p><b>{{ fax }}</b></p>
            </div>
          </div>

          <div class="contact-info-li">
            <div class="info-icon">
              <i class="iconfont icon-email"></i>
            </div>
            <div class="info-txt">
              <h4>{{ $t("email") }}</h4>
              <p><b>{{ email }}</b></p>
            </div>
          </div>

        </div>
        <div class="map">
          <iframe :src="url"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>
      <div class="contact-left contact-right">
        <h3>{{ $t("intro") }}</h3>
        <div class="contact-info">
          <Form></Form>
        </div>
      </div>
    </div>
  </div>
  <FooterInquiry></FooterInquiry>
</template>

<script>
import { ref } from 'vue'
import Form from "@/components/form";
import FooterInquiry from "@/components/FooterInquiry";
import Loading from "@/components/loading";
import {contactInfo} from "@/network/main";
export default {
  name: "Contact",
  components:{
    Form,
    FooterInquiry,
    Loading
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Contact Us - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  data() {
    return{
      isShow:true,
      banner:'',
      address:'',
      phone:'',
      fax:'',
      email:'',
      url:'',
      seo:'',
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      contactInfo().then(res =>{
        this.isShow = false;
        this.seo = res.data.data.seo;
        this.banner = res.data.data.banner;
        this.address = res.data.data.address;
        this.phone = res.data.data.phone;
        this.fax = res.data.data.fax;
        this.email = res.data.data.email;
        this.url = res.data.data.address_url;
      })
    },
  }
}
</script>

<style scoped>
.contact{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact{
  display: flex;
  justify-content: space-between;
}
.contact-left{
  width: 48%;
}
.contact-info{
  padding: 30px 0;
  background: #fff;
}
.contact-left h1{
  padding-bottom: 20px;
  border-bottom: 2px solid #000;
}
.contact-info-li{
  display: flex;
  padding: 10px 0;
  align-items: center;
}
.info-icon{
  border: 2px solid #000;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-icon i{
  color: #000;
}
.info-txt p b{
  padding-top: 10px;
  display:inline-block;
  font-size: 18px;
}
.info-txt h4{
  color: #606060;
}

.map iframe{
  width: 100%;
  height: 450px;
}

.contact-left iframe{
  width: 100%;
  height: 300px;
  border: none;
}
.contact-right{
  width: 40%;
}

.contact-right h1{
  font-size: 40px;
  padding-bottom: 20px;
}
.info-item{
  display: flex;
  padding: 10px 0;
  align-items: center;
}
.info-item span:first-child{
  padding-right: 10px;
}
.info-item span:first-child i{
  font-size: 22px;
  color: var(--background);
}

@media only screen and (max-width: 992px) {

  .contact-right{
    width: 100%;
  }
  .contact-right h1{
    font-size: 24px;
    padding-bottom:0;
  }
  .contact-left iframe{
    height: 300px;
    margin-top:20px;
  }
  .contact{
    flex-wrap: wrap;
  }
  .contact-left{
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-info{
    padding: 20px;
    min-height: inherit;
  }
  .map iframe {
    height: 300px;
  }
  .news-info h1{
    font-size: 24px;
  }
  .info-icon{
    margin-right: 10px;
  }

  .info-txt{
    width: calc(100% - 50px);
  }
}

</style>